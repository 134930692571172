import {
  CircularProgress,
  Flex,
  Heading,
  Image,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useRequest } from "ahooks";
import React, { useEffect } from "react";
import { useDrivingTipStore } from "../store/useDrivingTip";

interface Tip {
  id: number;
  title: string;
  url: string;
  order: number;
  thumbnail: string;
}
interface TipWithTitle {
  title: string;
  list: Tip[];
}

declare global {
  /* eslint-disable @typescript-eslint/no-unused-vars */
  interface Window {
    gtag: (
      key: string,
      trackingId: string,
      // eslint-disable-next-line camelcase
      config: { link_url: string },
    ) => void;
  }
}

export function TipList() {
  const { data, loading, refresh } = useRequest<TipWithTitle, []>(
    (): Promise<TipWithTitle> =>
      fetch("/api/winter-driving-tip/withtitle").then((x) => x.json()),
    {
      pollingInterval: 180 * 1000,
    },
  );

  const { setRefresh } = useDrivingTipStore();

  useEffect(() => {
    setRefresh(() => refresh);
  }, [refresh, setRefresh]);
  if (loading && !data) {
    return <CircularProgress isIndeterminate color="green.300" />;
  }
  if (typeof data === "undefined") {
    return null;
  }

  if (data && data.list.length > 0) {
    return (
      <>
        <Flex
          h="50px"
          w="100%"
          background="black"
          justifyContent="center"
          alignItems="center"
        >
          <Heading color="white">
            {data.title}
            {/*防災豆知識*/}
          </Heading>
        </Flex>
        <VStack m={4}>
          {data.list
            .sort((a, b) => a.order - b.order)
            .map((tip, index) => (
              <Flex
                key={tip.id}
                onClick={() => {
                  if (tip.url && tip.url.length > 0) {
                    window.gtag("event", "CLICK_TIPS", { link_url: tip.url });
                    window.open(tip.url);
                  } else {
                    return false;
                  }
                }}
                h="100px"
                w="100%"
              >
                <Image src={tip.thumbnail} w="30vw" h="100%" fit="cover" />
                <Flex
                  ml={2}
                  flexDirection="column"
                  justifyContent="space-between"
                  flex={1}
                >
                  <Text fontSize="lg" noOfLines={2}>
                    {tip.title}
                  </Text>
                </Flex>
              </Flex>
            ))}
        </VStack>
      </>
    );
  } else {
    return null;
  }
}
