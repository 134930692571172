import {
  Box,
  Center,
  ChakraProvider,
  extendTheme,
  Flex,
  Heading,
  HStack,
  Image,
  Button,
  Link,
  Text,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
} from "@chakra-ui/react";
import { AnnouncementList } from "./components/AnnouncementList";
import { SnowForecast } from "./components/SnowForecast";
import { TipList } from "./components/TipList";
import { useAnnouncementStore } from "./store/Announcement";
import { useSnowStore } from "./store/Snow";
import { useDrivingTipStore } from "./store/useDrivingTip";
import PullToRefresh from "react-simple-pull-to-refresh";

import { PrivacyPolicy } from "./components/PrivacyPolicy";
import "./App.css";

const logos = {
  enexco: `${process.env.PUBLIC_URL}/images/e-nexco.png`,
  cnexco: `${process.env.PUBLIC_URL}/images/c-nexco.jpg`,
  wnexco: `${process.env.PUBLIC_URL}/images/w-nexco.jpg`,
  wihighway: `${process.env.PUBLIC_URL}/images/w-ihighway.png`,
  cihighway: `${process.env.PUBLIC_URL}/images/c-ihighway.png`,
  dorapura: `${process.env.PUBLIC_URL}/images/logo_drapla.png`,
//  doratora: `${process.env.PUBLIC_URL}/images/doratora.png`,
  mewti: `${process.env.PUBLIC_URL}/images/mewti.png`,
};

const theme = extendTheme({
  components: {
    Heading: {
      baseStyle: {
        fontFamily: "Kosugi Maru",
      },
    },
    Text: {
      baseStyle: {
        fontFamily: "Kosugi Maru",
      },
    },
  },
});

const ImageButton = ({ src, link, ...props }: any) => (
  <Box
    onClick={() => {
      window.location.href = link;
    }}
  >
    <Image src={src} {...props} />
  </Box>
);

export default function Home() {
  const { refresh: refreshAnnouncement } = useAnnouncementStore();
  const { refresh: refreshSnow } = useSnowStore();
  const { refresh: refreshDrivingTip } = useDrivingTipStore();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleRefresh = async () => {
    await refreshAnnouncement();
    await refreshSnow();
    await refreshDrivingTip();
  };

  return (
    <ChakraProvider theme={theme}>
      {/*<Pullable*/}
      <PullToRefresh onRefresh={handleRefresh}>
        <Box w="100vw">
          <Box position="sticky" top={0} zIndex={1} background="white">
            <AnnouncementList />
          </Box>
          <SnowForecast />
          <Flex
            h="50px"
            w="100%"
            background="black"
            justifyContent="center"
            alignItems="center"
          >
            <Heading color="white">渋滞・規制情報</Heading>
          </Flex>
          <HStack m={4} alignItems="center">
            <ImageButton
              src={logos.wihighway}
              link="https://www.w-nexco.co.jp/realtime/"
            />
            <ImageButton
              src={logos.cihighway}
              //link="https://www.c-ihighway.jp/" // 2023/8/11変更
              link="https://www.c-ihighway.jp/pcsite/"
            />
            <ImageButton
              src={logos.dorapura}  w="300px"
              //src={logos.doratora} w="400px"
              //src={logos.cihighway} w="227px"
              link="https://www.driveplaza.com/"
            />
            <ImageButton
              src={logos.mewti}
              w="200px"
              transform="translateY(-2px)"
              link="https://search.shutoko-eng.jp/rtmap.html"
            />
          </HStack>
          <TipList />
          <Flex
            h="30px"
            w="100%"
            background="#FFCDCE"
            justifyContent="center"
            alignItems="center"
          >
            <Text color="black" fontSize="12px" fontWeight="bold">
              「故障車」や「落下物」・道路の破損などを見つけたら
            </Text>
          </Flex>
          <Center>
            <HStack color="#DB6D6E" alignItems="center" spacing={3}>
              <Text fontSize="16px" fontWeight="700">
                道路緊急ダイヤル
              </Text>
              <Link href="tel:#9910" fontSize="24px" fontWeight="800">
                #9910
              </Link>
            </HStack>
          </Center>
        </Box>
        {/*</Pullable>*/}
      </PullToRefresh>

      <Center>
        <Box
          fontSize="14px"
          fontFamily="Kosugi Maru"
          onClick={() => {
            onOpen();
          }}
        >
          Privacy
        </Box>
      </Center>
      <Modal
        scrollBehavior="inside"
        size="xl"
        motionPreset="slideInBottom"
        isOpen={isOpen}
        onClose={onClose}
        isCentered
      >
        <ModalOverlay />
        <ModalContent height="90vh">
          <ModalHeader fontFamily="Kosugi Maru">
            プライバシーポリシー
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody overflow="scroll">
            <PrivacyPolicy />
          </ModalBody>
          <ModalFooter>
            <Button onClick={onClose}>Close</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </ChakraProvider>
  );
}
