import { useState } from "react";

//import { createModel } from "hox";  // Mod20230703
import { createGlobalStore } from "hox";

function useSnow() {
  const [refresh, setRefresh] = useState<Function>(() => () => {});
  const [refreshing, setRefreshing] = useState(false);
  return {
    refresh,
    setRefresh,
    refreshing,
    setRefreshing,
  };
}

//export default createModel(useSnow);    // Mod20230703
export const [useSnowStore] = createGlobalStore(useSnow);
